@import './assets/sass/styles.scss';

.App {
  //   @font-face {
  //     font-family: "TitanOne";
  //     font-style: bold;
  //     font-weight: 600;
  //     src: url("/fonts/TitanOne-Regular.ttf");
  // }
  .pink {
    color: #db3082;
  }
  .underline {
    text-decoration: underline;
  }
  .mail-sent {
    color: rgb(0, 172, 0);
    font-size: 1.5rem;
    font-weight: 500;
    padding-top: 15px;
  }
  .button {
    background-color: #db3082;
    color: white;
    padding: 12px 25px;
    font-weight: 600;
    font-size: 1.1rem;
  }
  form {
    max-width: 50%;
    padding: 30px 0;
    .button {
      margin-top: 15px;
    }
  }
  .container {
    margin: 0 auto;
    max-width: 1280px;
    width: 90%;
  }
  .divider {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    height: 1px;
    padding: 10px 0;
  }
  .promo-logo,
  .promo-header,
  .promo-content {
    // padding: 0 16vw;
    padding: 0 10vw;
  }
  .promo-logo {
    padding-top: 2rem;
    padding-bottom: 2rem;

    .lang-switcher {
      position: absolute;
      top: 1rem;
      right: 2rem;
      a {
        color: #000000de;
        text-decoration: underline;
      }
    }
  }
  .promo-header {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    // background: rgb(166, 232, 63);
    // background: linear-gradient(
    //     135deg,
    //     rgba(166, 232, 63, 1) 0%,
    //     rgba(255, 255, 255, 1) 100%
    // );
    // background-image: url("/images/site/kuus.jpg");
    background-color: black;
    h1 {
      color: #fdc103;
      font-size: 10rem;
      font-weight: 600;
      font-family: TitanOne;
    }
    h2 {
      color: white;
      text-shadow: 1px 1px #ffffff30;
    }
    span {
      color: white;
      text-shadow: 1px 1px #ffffff30;
      padding-bottom: 5vh;
    }
    .buttons {
      padding: 5vh 0;
      .column {
        padding-top: 1rem;
      }
    }
  }
  .promo-content {
    background-color: rgba(255, 255, 255, 0.097);
    padding-top: 2rem;
    padding-bottom: 4rem;
    h2 {
      font-weight: 600;
      font-size: 1.5rem;
    }
    p {
      font-size: 1.2rem;
      padding: 10px 0;
    }
    .button-columns {
      padding: 0.75rem;
      margin-top: 2rem;
    }
    .iphone-img {
      text-align: center;
      img {
        max-height: 60vh;
      }
    }
    .boxes-header {
      text-align: center;
      font-weight: 600;
      font-size: 1.2rem;
      padding: 20px;
    }
    .icon-boxes {
      .box {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        min-height: 150px;
        .bold {
          font-weight: 600;
          display: block;
        }
        .icon {
          display: block;
        }
      }
    }
  }
  .page-forms {
    padding: 2rem;
  }
  .column {
    &.no-padding {
      padding: 0;
    }
    .main-content {
      margin: 0;
    }
  }
  .bold {
    font-weight: 600;
  }
  .text-link {
    color: #000000de;
    text-decoration: underline;
  }
  .big {
    font-size: 2rem;
    font-weight: 600;
  }
  .account-list {
    ul {
      // list-style: inside;
      padding-left: 2rem;
      li {
        list-style-type: '✓';
        padding-left: 1rem;
        // &::marker {
        //     content: "✓";
        // }
      }
    }
  }
  .price-example {
    .block {
      display: block;
      padding: 5px 0;
    }
  }
  .advantages-list {
    ul {
      padding-left: 2rem;
      li {
        list-style-type: '-';
        padding-left: 1rem;
      }
    }
  }

  .promo-footer {
    &#footer-wrapper {
      .columns {
        .column {
          justify-content: start;
          &.socials {
            a {
              font-size: 2rem;
              padding-right: 4px;
            }
          }
          li {
            padding-bottom: 5px;
          }
        }
      }
    }
  }

  /* MOBILE */
  @media (max-width: 992px) {
    form {
      max-width: 100%;
    }
    .container {
      width: 70%;
    }
    .promo-logo,
    .promo-header,
    .promo-content {
      padding: 0 8vw;
    }
    .promo-logo {
      img {
        max-height: 7rem;
      }
    }
    .promo-header {
      h2 {
        font-size: 2.8rem;
      }
    }
    .page-forms {
      .smart-login {
        .links-column {
          float: initial;
          text-align: initial;
        }
      }
    }

    .promo-footer {
      &#footer-wrapper {
        .columns {
          .column {
            float: initial;
          }
        }
      }
    }
  }
}
